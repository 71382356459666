import React, { useState, useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, Popover, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Home from '../home-view';
import Dashboard from '../dashboard-view';
import Profile20 from '../profile-view-2';
import Template from '../template-view';
import Guidance from '../planning-guidance-view';
import ProfileAi from '../profile-ai';
import JobBoardNewGradMain from '../jobboard-view/components/JobBoardNewGradMain';
import JobBoardInternMain from '../jobboard-view/components/JobBoardInternMain';
import { Tag } from '../../stories/Tag';
import Library from '../library-view';
import Interview from '../interview-view';
import Onboarding from '../onboarding-view';
import premiumBadge from '../../images/premium-badge.svg';
import AccountView from '../account-view';
import { npsHelper } from '../../services/JobBoardServices';
import LoadingProgress from '../../components/LoadingProgress';
import {
  logo,
  add,
  account,
  logoutIcon,
  guidanceLogo,
  jobDashboardLogo,
  jobBoardLogo,
  templateLogo,
  libraryLogo,
  profileLogo,
  questionBankLogo,
  rightArrow,
  downArrow,
  cvIcon,
  homeMenuIcon,
} from '../../components/icons';
import OnboardingModal from './components/OnboardingModal';
import './index.scss';
import ProfileContextMenu from '../../components/ProfileContextMenu2';
import AddNewDashboardModal from './components/AddNewDashboardModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { getInfoData } from '../../services/InfoPanelServices';
import { getDefaultDashboard } from '../../services/InfoPanelServices';
import { deleteDashboard } from '../../services/DashBoardServices';
import { createBlankProfile } from '../../services/ProfileServices';
import { useUpdateTaskStatus } from '@services/Home';
import LoginServices from '../../services/LoginServices';
import { useAuth0 } from '@auth0/auth0-react';
import { TOOLTIP_STATUS } from './constants';
import { popupError } from '../../utilities/error-handler';
import { errorMessages, libraryInfo } from './constants';
import { CHROME_EXTENSION_URL } from '../../constants/links';
import { Mixpanel } from '../../utilities/mixpanel';
import { OB_STEP, OB_STATUS } from '../../constants/onboarding';
import { TlmTaskStatus } from '@views/home-view/constant';
import queryString from 'query-string';
import { hotjar } from 'react-hotjar';
import ProfileValidateModal from '../../components/CommonDialog/ProfileValidateModal';
import { STARTER_PLAN_COUNT } from '../../constants/planCount';

import StripeSuccessModal from '../../components/CommonDialog/StripeSuccessModal';
import StripeFailedModal from '../../components/CommonDialog/StripeFailedModal';

/* FeatureFlags */
import { CreateOrToggleFeatureFlag } from '../../utilities/feature-flag';

//hotjar configuration
hotjar.initialize(2817243, 6);
hotjar.identify('USER_ID', { userProperty: 'value' });
hotjar.event('button-click');
hotjar.stateChange('/');

export const MainPageContext = React.createContext();

const AI_CV_BUILDER = 'ai-cv-builder';

const HOME_PAGE = 'homepage';

const MainView = (props) => {
  const pathname = props.location.pathname;
  const [data, setData] = useState({});
  const [view, setView] = useState('dashboard');
  const [refreshFlag, refresh] = useState(false);
  const [addDashboard, setAddDashboard] = useState(false);
  const [dashboardToDelete, setDashboardToDelete] = useState({
    id: '',
    name: '',
  });
  const [showDeleteProfile, setShowDeleteProfile] = useState(false);
  const [showDuplicateProfile, setShowDuplicateProfile] = useState(false);
  const [isPlanUpgradeModal, setPlanUpgradeModal] = useState(false);
  const [profileToDeleteOrDuplicate, setProfileToDeleteOrDuplicate] = useState({
    id: '',
    title: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [addProfile, setAddProfile] = useState(false);
  const [stripePaymentSuccess, setStripePaymentSuccess] = useState(false);
  const [stripePaymentFailed, setStripePaymentFailed] = useState(false);

  function handleCloseOnboardingModal() {
    setOpenOnboardingModal(false);
    setOnboardingStep(OB_STEP.DOWNLOAD_EXTENSION);
  }

  function handleCloseOnboarding() {
    setOpenOnboarding(false);
  }

  function handleCloseStripeSuccess() {
    setStripePaymentSuccess(false);
  }

  function handleCloseStripeFailed() {
    setStripePaymentFailed(false);
  }

  function handleCloseUpgradePlanModal() {
    setPlanUpgradeModal(false);
  }

  const [tooltipStatus, setTooltipStatus] = useState(TOOLTIP_STATUS.COMPLETE);

  const enableOnboarding = true;
  const [onboardingStep, setOnboardingStep] = useState(OB_STEP.LANDING);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(true);
  const [openOnboarding, setOpenOnboarding] = useState(true);
  const { isAuthenticated, logout } = useAuth0();
  const [arrow, setArrow] = useState({});
  const [currentPage, setCurrentPage] = useState({
    dashboard:
      pathname.indexOf('/dashboard') === ''
        ? ''
        : pathname.substring(pathname.indexOf('/dashboard') + 11),
  });

  /* feature flags */
  const [interviewFeatureFlag, setInterviewFeatureFlag] = useState(false);
  const [guidanceFeatureFlag, setGuidanceFeatureFlag] = useState(false);
  const [libraryFeatureFlag, setLibraryFeatureFlag] = useState(false);
  const [homeFlag, setHomeFlag] = useState(false);

  //install stonly and crisp
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'f645e9a7-db66-40f7-b90a-eea5de793b8a';
    (function () {
      var d = document;
      var s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();

    window.STONLY_WID = 'c3c501ff-4225-11ed-871a-0a52ff1ec764';
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.innerHTML =
      "!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,(g=new XMLHttpRequest).open('GET',n+'version?v='+Date.now(),!0),g.onreadystatechange=function(){4===g.readyState&&(y.src=n+'stonly-widget.js?v='+(200===g.status?g.responseText:Date.now()),(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())}(window,document,'script','https://stonly.com/js/widget/v2/') ";
    script.async = true;
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  const { mutateAsync: onUpdateTaskStatus } = useUpdateTaskStatus();

  useEffect(() => {
    const shouldShowOnboardModal = (step) => {
      if (step === OB_STEP.LANDING || tooltipStatus === TOOLTIP_STATUS.INIT)
        setOpenOnboardingModal(true);
    };
    const shouldShowOnboard = (step) => {
      if (!step) {
        setOpenOnboarding(false);
      }
    };
    const param = queryString.parse(window.location.search);
    const tlmRef = param['tlm-ref'];
    const storedStatus = localStorage.getItem(OB_STATUS);
    // 向后端发送请求，告知任务已完成
    const taskId = TlmTaskStatus[tlmRef];
    if (taskId) {
      onUpdateTaskStatus({ [taskId]: true });
    }
    //Set onboarding status based on url parameter or local storage
    if (Object.values(OB_STEP).includes(tlmRef)) {
      setOnboardingStep(tlmRef);
      localStorage.setItem(OB_STATUS, tlmRef);
      shouldShowOnboardModal(tlmRef);
      shouldShowOnboard(tlmRef);
    } else if (Object.values(OB_STEP).includes(storedStatus)) {
      setOnboardingStep(storedStatus);
      shouldShowOnboardModal(storedStatus);
      shouldShowOnboard(storedStatus);
    }
  }, []);

  /* Fetch Feature Flag Values or Create a new feature flag*/
  async function FeatureFlagFetch() {
    const enableHome = await CreateOrToggleFeatureFlag('enableHome');
    const interViewData = await CreateOrToggleFeatureFlag('Interview');
    const guidanceData = await CreateOrToggleFeatureFlag('Guidance');
    const libraryData = await CreateOrToggleFeatureFlag('Library');

    setInterviewFeatureFlag(interViewData);
    setGuidanceFeatureFlag(guidanceData);
    setLibraryFeatureFlag(libraryData);
    setHomeFlag(enableHome);
  }

  useEffect(() => {
    FeatureFlagFetch();
    // eslint-disable-next-line no-undef
    //StonlyWidget('openGuide', { guideId: 'BDklm82scg' });
    //Show stonly payment plan ready when user finish new user guide flow
    if (
      JSON.parse(localStorage.getItem('enablePanel')) === false &&
      localStorage.getItem('numOfDashboardNewUserGuideFlow') === '1' &&
      localStorage.getItem('numOfDashboardPremiumPlanTrailerGuideFlow') !== '1'
    ) {
      // eslint-disable-next-line no-undef
      StonlyWidget('openGuidedTour', { guideId: 'uvN7zIi1Jc' });
      localStorage.setItem('numOfDashboardPremiumPlanTrailerGuideFlow', '1');
    }
    //Trigger nps
    npsHelper('3').then((res) => {
      if (res.data === 1) {
        // eslint-disable-next-line no-undef
        hj('event', 'nps_survey');
      }
    });
  }, []);

  const arrowHandler = (name) => {
    setArrow({ [name]: !arrow[name] });
  };

  const getData = () => {
    getInfoData()
      .then((dataFromServer) => {
        setData(dataFromServer);
        if (enableOnboarding && !dataFromServer?.dashboards[0]?.user?.completed_tooltip) {
          setTooltipStatus(TOOLTIP_STATUS.INIT);
        }
      })
      .catch(() => {
        popupError(errorMessages.getData);
      });
  };

  const refreshMain = () => refresh(!refreshFlag);

  // 系统默认页面
  const goToDefaultPage = () => {
    if (homeFlag) {
      directToHomePage();
    } else {
      goToDefaultDashboard();
    }
  };

  useEffect(() => {
    getData();
    if (homeFlag && (pathname === '/' || pathname.indexOf('/homepage') === 0)) {
      directToHomePage();
    } else if (!homeFlag && (pathname === '/' || pathname.indexOf('/dashboard') === 0)) {
      setView('dashboard');
      if (currentPage.dashboard === '') {
        getDefaultDashboard()
          .then((id) => {
            setCurrentPage({ dashboard: id });
          })
          .catch((error) => {
            // if error status is 401, axios interceptors will redirect to /login or /home for us
            // in this case, there is no need to popup error
            if (!error?.response?.status === 401) {
              popupError(errorMessages.getData);
            }
          });
      } else if (currentPage.dashboard.length === 11) {
        setCurrentPage({ dashboard: currentPage });
      }
      setArrow({ dashboard: true });
    } else if (pathname.indexOf('/profile/') === 0) {
      setView('profile');
      setCurrentPage({ profile: props.match.params?.id });
      setArrow({ profile: true });
    } else if (pathname.indexOf('/subscription-success') === 0) {
      setView('dashboard');
      homeFlag
        ? directToHomePage()
        : getDefaultDashboard()
            .then((id) => {
              setCurrentPage({ dashboard: id });
            })
            .catch((error) => {
              // if error status is 401, axios interceptors will redirect to /login or /home for us
              // in this case, there is no need to popup error
              if (!error?.response?.status === 401) {
                popupError(errorMessages.getData);
              }
            });

      setArrow({ dashboard: true });
      setStripePaymentSuccess(true);
      Mixpanel.track('UpgradePremiumComplete');
    } else if (pathname.indexOf('/subscription-failed') === 0) {
      homeFlag
        ? directToHomePage()
        : getDefaultDashboard()
            .then((id) => {
              setCurrentPage({ dashboard: id });
            })
            .catch((error) => {
              // if error status is 401, axios interceptors will redirect to /login or /home for us
              // in this case, there is no need to popup error
              if (!error?.response?.status === 401) {
                popupError(errorMessages.getData);
              }
            });

      setArrow({ dashboard: true });
      setStripePaymentFailed(true);
      Mixpanel.track('UpgradePremiumIncomplete');
    } else if (pathname.indexOf('/account') === 0) {
      setView('account');
      setCurrentPage({ account: 'account' });
    } else if (pathname.indexOf('/template') === 0) {
      setView('template');
      setCurrentPage({ template: 'template' });
    } else if (pathname.indexOf('/library') === 0) {
      if (pathname.includes('/LibraryResources')) {
        setCurrentPage({ library: 'LibraryResources' });
      } else {
        setCurrentPage({ library: 'MyCollection' });
      }
      setView('library');
      setArrow({ library: true });
    } else if (pathname.indexOf('/job-board') === 0) {
      setView('job-board');
      let subPage = pathname.split('/job-board/')[1];
      setCurrentPage({ jobboard: subPage });
      setArrow({ jobboard: true });
    } else if (pathname.indexOf('/planning-guidance') === 0) {
      setView('guidance');
      setCurrentPage({ guidance: 'guideNUX' });
      setArrow({ guidance: true });
    } else if (pathname.indexOf('/interview') === 0) {
      setView('interview');
      setCurrentPage({ interview: 'interview' });
    } else if (pathname.indexOf('/' + AI_CV_BUILDER) === 0) {
      setView(AI_CV_BUILDER);
      setCurrentPage({ profileAi: AI_CV_BUILDER });
    }
  }, [refreshFlag, homeFlag]);

  //Todo: seems not working due to useEffect get data delay
  const goToDefaultDashboard = () => {
    if (data && data.dashboards && data.dashboards.length === 0) return;
    const dashboardId = data.dashboards[0].id;
    props.history.push(`/dashboard/${dashboardId}`);
    setView('dashboard');
    setCurrentPage({ dashboard: dashboardId });
    refreshMain();
  };

  const directToHomePage = (path = `/${HOME_PAGE}`, view = HOME_PAGE) => {
    Mixpanel.track('View Home Page');
    setView(view);
    setShowBanner(false);
    setArrow({});
    setCurrentPage({ home: view });
    props.history.push(path);
  };
  // triggered when user clicks dashboard id in left panel
  const directToDashboard = (dashboardId) => {
    Mixpanel.track('View Dashboard');
    props.history.push(`/dashboard/${dashboardId}`);
    setView('dashboard');
    setShowBanner(false);
    setArrow({ dashboard: true });
    setCurrentPage({ dashboard: dashboardId });
  };

  const directToJobBoard = (path, view) => {
    getData();
    Mixpanel.track('View Jobboard Page');
    setView(view);
    setShowBanner(false);
    setCurrentPage({ jobboard: view });
    setArrow({ jobboard: true });
    props.history.push(path);
  };

  const directToProfile = (profile) => {
    Mixpanel.track('View Profile Page', { profileId: profile });

    if (profile === undefined || profile === '') {
      goToDefaultDashboard();
    }

    setView('profile');
    setArrow({ profile: true });
    setShowBanner(false);
    setCurrentPage({ profile: profile });
    props.history.push(`/profile/${profile}`);
  };

  const createNewProfile = () => {
    arrowHandler('profile');
    if (data.profiles.length > 0) {
      directToProfile(data.profiles.titles[0].id);
    }
    setAddProfile(true);
  };

  const directToProfileAiBuilder = (path = `/${AI_CV_BUILDER}`, view = AI_CV_BUILDER) => {
    Mixpanel.track('View AI Resume Builder Page');
    setView(view);
    setShowBanner(false);
    setArrow({});
    setCurrentPage({ profileAi: view });
    props.history.push(path);
  };

  const directToTemplate = () => {
    setView('template');
    setShowBanner(false);
    setArrow({});
    setCurrentPage({ template: 'template' });
    props.history.push(`/template`);
  };

  const directToGuidance = (guidance) => {
    if (guidance) {
      setCurrentPage({ guidance: guidance });
    } else {
      setCurrentPage({});
    }
    props.history.push(`/planning-guidance`);
    setView('guidance');
    setShowBanner(false);
  };

  const directToLibrary = (library) => {
    if (library === undefined || library === '') {
      goToDefaultPage();
    }
    setView('library');
    setShowBanner(false);
    setCurrentPage({ library: library });
    props.history.push(`/library/${library}`);
  };

  const directToInterview = () => {
    setView('interview');
    setShowBanner(false);
    setArrow({});
    setCurrentPage({ interview: 'interview' });
    props.history.push(`/interview`);
  };

  const directToAccountService = () => {
    getData();
    setView('account');
    setCurrentPage({ account: 'account' });
    props.history.push(`/account`);
  };

  const directToPlanSelection = () => {
    props.history.push(`/plan-selection`);
    Mixpanel.track('Click UpgradeToPremium_NaviBar');
  };

  const [isAdding, setIsAdding] = useState(false);
  const handleAddProfile = (title) => {
    setIsAdding(true);
    return createBlankProfile(title)
      .then((res) => {
        Mixpanel.track('Add New Profile', { title: title });
        setIsAdding(false);
        setAddProfile(false);
        setData({
          ...data,
          profiles: res.data,
        });
        const pid = res.data.titles.find((p) => p.title === title).id;
        setCurrentPage({ profile: pid });
        directToProfile(pid);
      })
      .catch(() => {
        setIsAdding(false);
        popupError(errorMessages.addProfile);
      });
  };

  function handleOnboardingStep(step) {
    setOnboardingStep(step);
  }

  function handleNextStripeSuccessStep() {
    directToAccountService();
  }

  const dashboardTitleRef = useRef(null);
  const profileTitleRef = useRef(null);
  const templateTitleRef = useRef(null);
  const guidanceTitleRef = useRef(null);
  const libraryTitleRef = useRef(null);
  const dashboardNum = data.dashboards?.length || 1;
  const profileNum = data.profiles?.titles.length || 1;

  const onAddNewDashboard = (dashboardId) => {
    setAddDashboard(false);
    directToDashboard(dashboardId);
    refreshMain();
  };

  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {}, []);
  if (Object.keys(data).length === 0)
    return (
      <div className="tlm-main-loading-container">
        <LoadingProgress />
      </div>
    );

  const homeMenu = (
    <ul className="tlm-main-profile-ai">
      <div
        className={`tlm-main-nav-title-panel${
          currentPage.home === HOME_PAGE ? '-current' : ''
        }`}
        onClick={() => {
          if (!currentPage.home) {
            directToHomePage(`/${HOME_PAGE}`, HOME_PAGE);
          }
        }}
      >
        <div className="tlm-main-single-title">
          <div className="tlm-main-nav-logo">
            <span className="menu-icon">{homeMenuIcon}</span>
          </div>
          <span
            className={`tlm-main-first-label ${
              currentPage.home === HOME_PAGE ? 'bold' : ''
            }`}
          >
            Home
          </span>
        </div>
      </div>
    </ul>
  );

  function dashboardPopupCheck() {
    let addBtn = null;
    addBtn = (
      <span
        className="tlm-main-add-btn"
        onClick={(e) => {
          e.stopPropagation();
          setAddDashboard(true);
        }}
      >
        {add}
      </span>
    );
    return addBtn;
  }

  const dashboardList = (
    <ul
      className={
        currentPage.dashboard
          ? 'tlm-main-dashboard-list-current'
          : 'tlm-main-dashboard-list'
      }
    >
      <div
        className="tlm-main-nav-title-panel"
        ref={dashboardTitleRef}
        onClick={() => {
          arrowHandler('dashboard');
          if (!currentPage.dashboard) {
            directToDashboard(data.dashboards[0].id);
          }
        }}
      >
        <div className="tlm-main-nav-logo">
          {arrow.dashboard ? downArrow : rightArrow}
        </div>
        <div className="tlm-main-nav-logo">{jobDashboardLogo}</div>
        <span className="tlm-main-first-label">My Dashboards</span>
        {dashboardPopupCheck()}
      </div>

      {arrow.dashboard ? (
        <div>
          {data.dashboards.map((dashboard) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={dashboard.id}
              className={
                dashboard.id === currentPage.dashboard
                  ? 'tlm-main-item-current'
                  : 'tlm-main-item'
              }
              onClick={() => directToDashboard(dashboard.id)}
            >
              <div
                className={
                  dashboard.id === currentPage.dashboard
                    ? 'tlm-main-title-current'
                    : 'tlm-main-title'
                }
              >
                {dashboard.name}{' '}
              </div>
              {dashboardNum > 1 && (
                <DeleteIcon
                  sx={{ width: 18, height: 18, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDashboardToDelete({ id: dashboard.id, name: dashboard.name });
                  }}
                />
              )}
            </Stack>
          ))}
        </div>
      ) : (
        <div />
      )}
    </ul>
  );

  const handleAddProfileClick = (e) => {
    e.stopPropagation();
    setAddProfile(true);
    Mixpanel.track('Add New Profile');
  };

  const profileCheckPlanStatus = (is_subscripted, profile) => {
    // First, check if the user is subscribed
    const isSubscribed = is_subscripted;

    // Next, check if the current page is a profile page
    const isProfilePage = profile;

    // Check if the user is subscribed
    if (isSubscribed) {
      // If the user is subscribed, check if it's a profile page
      if (isProfilePage) {
        return (
          <span className="tlm-main-add-btn" onClick={handleAddProfileClick}>
            {add}
          </span>
        );
      } else {
        // If it's not a profile page, return an empty span
        return <span />;
      }
    } else {
      // If the user is not subscribed, check if there are enough profiles based on the plan
      if (data.profiles.titles.length >= STARTER_PLAN_COUNT.PROFILE_COUNTS) {
        return (
          <span
            className="tlm-main-add-btn"
            onClick={() => {
              handlePlanCheck();
            }}
          >
            {add}
          </span>
        );
      } else {
        // If there are not enough profiles based on the plan, check if it's a profile page
        if (isProfilePage) {
          return (
            <span className="tlm-main-add-btn" onClick={handleAddProfileClick}>
              {add}
            </span>
          );
        } else {
          // If it's not a profile page, return an empty span
          return <span />;
        }
      }
    }
  };

  // profile2.0

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const handlePlanCheck = () => {
    setPlanUpgradeModal(true);
    Mixpanel.track('UpgradeToPremiumPopup_Profile');
  };

  const profileList20 = (
    <ul
      className={
        currentPage.profile ? 'tlm-main-profile-list-current' : 'tlm-main-profile-list'
      }
    >
      <div
        className="tlm-main-nav-title-panel"
        ref={profileTitleRef}
        onClick={() => {
          arrowHandler('profile');
          if (!currentPage.profile) {
            directToProfile(data.profiles.titles[0].id);
          }
        }}
      >
        <div className="tlm-main-nav-logo">{arrow.profile ? downArrow : rightArrow}</div>
        <div className="tlm-main-nav-logo">{profileLogo}</div>
        <span className="tlm-main-first-label">Application Profile</span>
        {profileCheckPlanStatus(
          data.dashboards[0].user.is_subscripted,
          currentPage.profile
        )}
      </div>

      {arrow.profile ? (
        <div>
          {data.profiles.titles.map((profile) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={profile.id}
              className={`tlm-main-item ${
                profile.id === currentPage.profile ? 'tlm-main-list-focus-style' : ''
              }`}
              onClick={() => directToProfile(profile.id)}
            >
              <div
                className={`tlm-main-ellipsis tlm-main-title${
                  profile.id === currentPage.profile ? '-current' : ''
                }`}
              >
                {profile.title}{' '}
              </div>

              <div>
                <Stack
                  sx={{ paddingRight: '2px' }}
                  id={profile.id}
                  onClick={handlePopoverOpen}
                >
                  <MoreVertIcon fontSize="16" />
                </Stack>
                <Popover
                  id={profile.id + '_Popover'}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={anchorEl && anchorEl.id === profile.id}
                  anchorEl={anchorEl}
                  onClose={(e) => {
                    e.stopPropagation();
                    handlePopoverClose();
                  }}
                  disableRestoreFocus
                  sx={{
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.13)',
                  }}
                >
                  {data.dashboards[0].user.is_subscripted ? (
                    <MenuItem
                      className="profile-2-menu-handler"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopoverClose();
                        setShowDuplicateProfile(true);
                        setProfileToDeleteOrDuplicate({
                          id: profile.id,
                          title: profile.title,
                        });
                      }}
                    >
                      Duplicate
                    </MenuItem>
                  ) : (
                    <MenuItem
                      className="profile-2-menu-handler"
                      onClick={() => handlePlanCheck()}
                    >
                      Duplicate
                    </MenuItem>
                  )}

                  {profileNum > 1 && (
                    <MenuItem
                      className="profile-2-menu-handler"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopoverClose();
                        setShowDeleteProfile(true);
                        setProfileToDeleteOrDuplicate({
                          id: profile.id,
                          title: profile.title,
                        });
                      }}
                    >
                      Delete
                    </MenuItem>
                  )}
                </Popover>
              </div>
            </Stack>
          ))}
        </div>
      ) : null}
    </ul>
  );
  const profileAiMenu = (
    <ul className="tlm-main-profile-ai">
      <div
        className={`tlm-main-nav-title-panel${
          currentPage.profileAi === AI_CV_BUILDER ? '-current' : ''
        }`}
        onClick={() => {
          if (!currentPage.profileAi) {
            directToProfileAiBuilder(`/${AI_CV_BUILDER}`, AI_CV_BUILDER);
          }
        }}
      >
        <div className="tlm-main-single-title">
          <div className="tlm-main-nav-logo">{cvIcon}</div>
          <span
            className={`tlm-main-first-label ${
              currentPage.profileAi === AI_CV_BUILDER ? 'bold' : ''
            }`}
          >
            AI Resume Builder
          </span>
          <Tag label={'Beta'} themeColor="c-attention-orange" className="tlm-new-tag" />
        </div>
      </div>
    </ul>
  );

  const emailTemplate = (
    <ul className={'tlm-main-email-template'}>
      <div
        className={`tlm-main-nav-title-panel${
          currentPage.template === 'template' ? '-current' : ''
        }`}
        ref={templateTitleRef}
        onClick={() => {
          directToTemplate();
        }}
      >
        <div className="tlm-main-single-title">
          <div className="tlm-main-nav-logo">{templateLogo}</div>
          <span className="tlm-main-first-label">Template</span>
        </div>
      </div>
    </ul>
  );

  //ContextMenu already removed, should update the trigger method once we activate guidance
  const guidanceList = (
    <ul
      className={
        currentPage.guidance ? 'tlm-main-guidance-list-current' : 'tlm-main-guidance-list'
      }
    >
      <div
        className={
          currentPage.guidance === 'guideNUX'
            ? 'tlm-main-nav-title-panel-current'
            : 'tlm-main-nav-title-panel'
        }
        ref={guidanceTitleRef}
        onClick={() => {
          data.guidances.length !== 0
            ? directToGuidance(data.guidances[0])
            : directToGuidance('guideNUX');
          arrowHandler('guidance');
        }}
      >
        {data.guidances.length !== 0 ? (
          <div className="tlm-main-nav-logo">
            {arrow.guidance ? downArrow : rightArrow}
          </div>
        ) : (
          <span />
        )}
        {
          <span className={data.guidances.length !== 0 ? '' : 'tlm-main-single-title'}>
            <div className="tlm-main-nav-logo">{guidanceLogo}</div>
            <span className="tlm-main-first-label">Guidance</span>
          </span>
        }
        {currentPage.guidance ? (
          <span
            className="tlm-main-add-btn"
            onClick={(e) => {
              e.stopPropagation();
              directToGuidance('guideNUX');
            }}
          >
            {add}
          </span>
        ) : (
          <span />
        )}
      </div>
      {arrow.guidance ? (
        <div>
          {data.guidances.map((guidance) => (
            <li
              key={guidance.id}
              className={`tlm-main-item ${
                guidance.id === (currentPage.guidance ? currentPage.guidance.id : null)
                  ? 'tlm-main-list-focus-style'
                  : ''
              }`}
              onClick={() => directToGuidance(guidance)}
            >
              <div className="tlm-main-title-wrapper">
                <span
                  className={`tlm-main-ellipsis tlm-main-title${
                    guidance.id ===
                    (currentPage.guidance ? currentPage.guidance.id : null)
                      ? '-current'
                      : ''
                  }`}
                >
                  {guidance.title}{' '}
                </span>
              </div>
            </li>
          ))}
        </div>
      ) : (
        <div />
      )}
    </ul>
  );

  const jobBoard = (
    <ul
      className={
        currentPage.jobboard ? 'tlm-main-job-board-current' : 'tlm-main-job-board'
      }
    >
      <div
        className="tlm-main-nav-title-panel"
        onClick={() => {
          arrowHandler('jobboard');
          if (!currentPage.jobboard) {
            directToJobBoard('/job-board/ng-opportunity', 'ng-opportunity');
          }
        }}
      >
        <div className="tlm-main-nav-logo">{arrow.jobboard ? downArrow : rightArrow}</div>
        <div className="tlm-main-nav-logo">{jobBoardLogo}</div>
        <span className="tlm-main-first-label">Job Board</span>
        <Tag label={'Beta'} themeColor="c-attention-orange" className="tlm-new-tag" />
      </div>
      {arrow.jobboard ? (
        <div>
          <li
            className={`tlm-main-item ${
              currentPage.jobboard === 'ng-opportunity' ? 'tlm-main-list-focus-style' : ''
            }`}
            onClick={() => {
              directToJobBoard('/job-board/ng-opportunity', 'ng-opportunity');
              Mixpanel.track('View New Grad Job Board Page');
            }}
          >
            <span
              className={`tlm-main-ellipsis tlm-main-title tlm-main-title-${
                currentPage.jobboard === 'ng-opportunity' ? 'current' : ''
              }`}
            >
              New Grad Jobs
            </span>
          </li>

          <li
            className={`tlm-main-item ${
              currentPage.jobboard === 'intern-opportunity'
                ? 'tlm-main-list-focus-style'
                : ''
            }`}
            onClick={() => {
              Mixpanel.track('View Intern Job Board Page');
              directToJobBoard('/job-board/intern-opportunity', 'intern-opportunity');
            }}
          >
            <span
              className={`tlm-main-ellipsis tlm-main-title tlm-main-title-${
                currentPage.jobboard === 'intern-opportunity' ? 'current' : ''
              }`}
            >
              Intern Jobs
            </span>
          </li>
        </div>
      ) : (
        <div />
      )}
    </ul>
  );

  const libraryList = (
    <ul
      className={
        currentPage.library ? 'tlm-main-library-list-current' : 'tlm-main-library-list'
      }
    >
      <div
        className="tlm-main-nav-title-panel"
        onClick={() => {
          arrowHandler('library');
          if (!currentPage.library) {
            directToLibrary(libraryInfo[0].split(' ').join(''));
          }
        }}
        ref={libraryTitleRef}
      >
        <div className="tlm-main-nav-logo">{arrow.library ? downArrow : rightArrow}</div>
        <div className="tlm-main-nav-logo">{libraryLogo}</div>
        <span className="tlm-main-first-label">Library</span>
      </div>
      {arrow.library ? (
        <div>
          {libraryInfo.map((library) => (
            <li
              key={library}
              className={`tlm-main-item ${
                library.split(' ').join('') === currentPage.library
                  ? 'tlm-main-list-focus-style'
                  : ''
              }`}
              onClick={() => directToLibrary(library.split(' ').join(''))}
            >
              <div className="tlm-main-title-wrapper">
                <span
                  className={`tlm-main-ellipsis tlm-main-title${
                    library.split(' ').join('') === currentPage.library ? '-current' : ''
                  }`}
                >
                  {library}{' '}
                </span>
              </div>
            </li>
          ))}
        </div>
      ) : (
        <div />
      )}
    </ul>
  );

  const interview = (
    <ul className="tlm-main-interview">
      <div
        className={`tlm-main-nav-title-panel${
          currentPage.interview === 'interview' ? '-current' : ''
        }`}
        onClick={() => {
          directToInterview();
        }}
      >
        <div className="tlm-main-single-title">
          <div className="tlm-main-nav-logo">{questionBankLogo}</div>
          <span className="tlm-main-first-label">Question Bank</span>
        </div>
      </div>
    </ul>
  );

  // User ID set localStorage
  localStorage.setItem('userID', data.dashboards[0].user.id);

  return (
    <div className="tlm-main">
      <MainPageContext.Provider value={data}>
        {(view === 'dashboard' || view === 'profile' || view !== '') && (
          <section
            className={`tlm-plugin-section tlm-text-small ${showBanner ? '' : ' d-none'}`}
          >
            {view === 'profile'
              ? 'Autofill any application form using our'
              : 'Save job with one click using our'}
            <a target="_blank" href={CHROME_EXTENSION_URL} rel="noopener noreferrer">
              <b>Chrome extension</b>
            </a>
          </section>
        )}
        <div className={`tlm-main-content-panel${showBanner ? '' : '-no-banner'}`}>
          <div className="tlm-main-content">
            <div className="tlm-main-left-info-panel">
              <div className="tlm-main-logo-div">
                {logo}
                <span className="tlm-main-logo-label">Offerland</span>
              </div>
              <div className="tlm-main-lists">
                <div className="tlm-main-category">
                  <div className="tlm-main-category-name">Apply</div>
                  {homeFlag && homeMenu}
                  {dashboardList}
                  {jobBoard}
                  {profileList20}
                  {profileAiMenu}
                </div>
                {interviewFeatureFlag && (
                  <div className="tlm-main-category">
                    <div className="tlm-main-category-name">Interview</div>
                    {interview}
                  </div>
                )}
                <div className="tlm-main-category">
                  <div className="tlm-main-category-name">Resources</div>
                  {guidanceFeatureFlag && guidanceList}
                  {emailTemplate}
                  {libraryFeatureFlag && libraryList}
                </div>
              </div>
              {!data.dashboards[0]?.user?.is_subscripted && (
                <div className="tlm-main-premium">
                  <div
                    className="tlm-main-premium-widget"
                    onClick={() => directToPlanSelection()}
                  >
                    <div className="tlm-main-premium-widget-label">
                      <img
                        className="tlm-main-premium-widget-label-img"
                        src={premiumBadge}
                        alt="Premium Badge"
                      />
                    </div>
                    <span className="tlm-main-premium-widget-content">
                      Upgrade to Premium Now!
                    </span>
                  </div>
                </div>
              )}
              <div className={`tlm-main-setting-btn-div ${view === 'account' && 'test'}`}>
                <span
                  className="tlm-main-user-label"
                  onClick={() => directToAccountService()}
                >
                  {account} {data.dashboards[0]?.user?.first_name}
                </span>

                <span className="tlm-main-setting-btn">
                  {/*{setting}*/}
                  <span
                    title="Logout"
                    onClick={() => {
                      Mixpanel.track('Logout');
                      localStorage.removeItem('is_gotIt');
                      isAuthenticated ? logout() : LoginServices.logout();
                    }}
                  >
                    {logoutIcon}
                  </span>
                </span>
              </div>
            </div>
            {view === 'homepage' && (
              <div className="tlm-main-right-info-panel">
                <Home
                  defaultDashboardId={data.dashboards[0].id}
                  defaultProfileId={data.profiles.default_profile_id}
                  goDashboard={directToDashboard}
                  goProfile={directToProfile}
                  goJobBoard={() =>
                    directToJobBoard('/job-board/ng-opportunity', 'ng-opportunity')
                  }
                  refreshMain={refreshMain}
                />
              </div>
            )}
            {view === 'dashboard' && (
              <div className="tlm-main-right-info-panel">
                <Dashboard
                  defaultProfileId={
                    data.profiles?.titles?.length > 0 ? data.profiles.titles[0].id : null
                  }
                  setMainPageData={setData}
                  dashboardId={currentPage.dashboard}
                  dashboardList={data.dashboards}
                  refreshMain={refreshMain}
                  goDashboard={directToDashboard}
                  directToProfileAiBuilder={directToProfileAiBuilder}
                  directToProfile={directToProfile}
                />
              </div>
            )}
            {view === 'profile' && (
              <div className="tlm-main-right-info-panel">
                <Profile20
                  profileId={currentPage.profile}
                  refreshMain={refreshMain}
                  directToProfileAiBuilder={directToProfileAiBuilder}
                />
              </div>
            )}
            {view === 'account' && (
              <div className="tlm-main-right-info-panel">
                <AccountView getAllRecords={data} />
              </div>
            )}
            {currentPage.jobboard === 'ng-opportunity' && (
              <div className="tlm-main-right-info-panel">
                <JobBoardNewGradMain
                  defaultDashboard={data.dashboards[0]}
                  directToDashboard={directToDashboard}
                  arrowHandler={arrowHandler}
                  directToProfileAiBuilder={directToProfileAiBuilder}
                />
              </div>
            )}

            {currentPage.jobboard === 'intern-opportunity' && (
              <div className="tlm-main-right-info-panel">
                <JobBoardInternMain
                  defaultDashboard={data.dashboards[0]}
                  directToDashboard={directToDashboard}
                  arrowHandler={arrowHandler}
                />
              </div>
            )}
            {view === 'template' && (
              <div className="tlm-main-right-info-panel">
                <Template refreshMain={refreshMain} />
              </div>
            )}
            {view === 'guidance' && (
              <div className="tlm-main-right-info-panel">
                <Guidance
                  guidance={currentPage.guidance}
                  userGuides={data.guidances}
                  directToGuidance={directToGuidance}
                  refreshMain={refreshMain}
                />
              </div>
            )}

            {view === 'library' && (
              <div className="tlm-main-right-info-panel">
                <Library libraryTab={currentPage.library} refreshMain={refreshMain} />
              </div>
            )}
            {view === 'interview' && (
              <div className="tlm-main-right-info-panel">
                <Interview />
              </div>
            )}

            {view === AI_CV_BUILDER && (
              <div className="tlm-main-right-info-panel">
                <ProfileAi
                  getAllRecords={data}
                  directToProfile={directToProfile}
                  profiles={data.profiles}
                  createNewProfile={createNewProfile}
                  refreshMain={refreshMain}
                />
              </div>
            )}
          </div>
          <ProfileValidateModal
            open={isPlanUpgradeModal}
            handleClose={handleCloseUpgradePlanModal}
          />
          <AddNewDashboardModal
            getData={getData}
            setView={setView}
            setAddDashboard={setAddDashboard}
            addDashboard={addDashboard}
            directToDashboard={directToDashboard}
            onAddNewDashboard={onAddNewDashboard}
          />
          <DeleteConfirmationModal
            show={dashboardToDelete.id.length !== 0}
            deleteField="Dashboard"
            fieldName={dashboardToDelete.name}
            onCancelFunc={() => {
              setDashboardToDelete({ id: '', name: '' });
            }}
            onConfirmFunc={() => {
              deleteDashboard(dashboardToDelete.id)
                .then(() => {
                  // todo
                  if (currentPage.dashboard === dashboardToDelete.id) {
                    goToDefaultDashboard();
                  }
                  refreshMain();
                  Mixpanel.track('Delete Dashboard');
                })
                .catch(() => {
                  popupError(errorMessages.deleteDashboard);
                })
                .finally(() => {
                  setDashboardToDelete({ id: '', name: '' });
                });
            }}
            onHideFunc={() => {
              setDashboardToDelete({ id: '', name: '' });
            }}
          />
          <ProfileContextMenu
            id={'tlm-profile-context_menu-' + profileToDeleteOrDuplicate.id}
            showAddProfile={addProfile}
            setShowAddProfile={setAddProfile}
            onAddNewProfile={handleAddProfile}
            loading={isAdding}
            setShowDuplicateProfile={setShowDuplicateProfile}
            setShowDeleteProfile={setShowDeleteProfile}
            showDuplicateProfile={showDuplicateProfile}
            showDeleteProfile={showDeleteProfile}
            currentProfile={currentPage.profile}
            profileId={profileToDeleteOrDuplicate.id}
            profileTitle={profileToDeleteOrDuplicate.title}
            profiles={data.profiles}
            redirectToProfile={directToProfile}
            refresh={refreshMain}
          />
        </div>
      </MainPageContext.Provider>
      <StripeSuccessModal
        open={stripePaymentSuccess}
        handleClose={handleCloseStripeSuccess}
        handleNextStep={handleNextStripeSuccessStep}
      />

      <StripeFailedModal
        open={stripePaymentFailed}
        handleClose={handleCloseStripeFailed}
      />

      {!homeFlag && onboardingStep !== OB_STEP.LANDING && openOnboarding && (
        <Onboarding
          step={onboardingStep}
          setStep={handleOnboardingStep}
          profileId={data?.profiles?.default_profile_id}
          directToProfile={directToProfile}
          handleClose={handleCloseOnboarding}
        />
      )}

      {!homeFlag && onboardingStep === OB_STEP.LANDING && (
        <OnboardingModal
          open={openOnboardingModal}
          setStep={setOnboardingStep}
          handleClose={handleCloseOnboardingModal}
        />
      )}
    </div>
  );
};

export default MainView;
