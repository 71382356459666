export const roleList = [
  'Software Engineer',
  'Product Manager',
  'Data Scientist',
  'Full-stack Developer',
  'Front-end Developer',
  'Java Developer',
  'DevOps Engineer',
  'Product Designer',
  'UX Designer',
  'Technical Program Manager',
  'Technical Support Engineer',
  'Network Engineer',
  'Cloud Engineer',
  'Security Engineer',
  'Mobile Developer',
  'Marketing Analyst',
  'Financial Analyst',
  'User Researcher',
  'Business Analyst',
  'Data Analyst',
  'iOS Developer',
  'Android Developer',
  'Solutions Architect',
  'UI Designer',
  'Database Administrator',
  'Project Manager',
  'Machine Learning Engineer',
  'Quality Assurance Engineer',
  'Data Engineer',
  'Cybersecurity Analyst',
  'Content Manager',
  'Sales Representative',
  'IT Support Specialist',
];
export const levelList = ['Internship', 'Entry Level', 'Mid-Senior Level'];
