import React, { useState, useEffect } from 'react';

// components
import PropTypes from 'prop-types';
import JobBoardHeader from './components/JobBoardHeader';
import DashBoardMainContent from './components/DashBoardMainContent';
// import { getDataOnDashboard } from '../../services/DashBoardServices';
import { npsHelper } from '../../services/JobBoardServices';
// styling
import './index.scss';
import './react-tabs.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// utilities
import clsx from 'clsx';
import { Mixpanel } from '../../utilities/mixpanel';
import { AiCvNoticeInfo } from '@views/main-view/components/AiCvNoticeInfo';
/**
 *
 * @param
 * dashboardId: represent current dashboard ID
 */
import { useDashboardData } from '../../services/Dashboard';
// ----------------------------------------------------------------------

export const DashboardContext = React.createContext();
const STATUSTYPE = [
  'Wishlist',
  'Applied',
  '1st round',
  '2nd round',
  '3rd round',
  'Onsite',
  'Final round',
  'Offer',
  'Reject',
];
const STATUSTYPE_MAP = {
  Wishlist: 1,
  Applied: 2,
  '1st round': 3,
  '2nd round': 4,
  '3rd round': 5,
  Onsite: 6,
  'Final round': 7,
  Offer: 8,
  Reject: 9,
};
const defaultPage = {
  obj_per_page: 25, // 每一页的obj的个数
  after: 0, // 从哪一页后开始返回数据
};
const defaultQuery = {
  getlist: true,
  pagination: defaultPage,
  sort: {},
  filters: {},
  keyword: '',
};
function Dashboard({
  dashboardId,
  dashboardList,
  setMainPageData,
  directToProfileAiBuilder,
  goDashboard,
  defaultProfileId,
  directToProfile,
}) {
  const [rowSelected, setRowSelected] = useState([]);
  const [refreshPageFlag, setRefreshPageFlag] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [changeLayOutFlag, setChangeLayOutFlag] = useState(false);
  const [originalRows, setRows] = useState([]);
  const [searched, setSearched] = useState(false);

  const handleSearch = (searchText) => {
    setRowSelected([]);
    if (!searched) {
      Mixpanel.track('Search Job', {
        SearchQuery: searchText,
      });
      setSearched(true);
    }
    setSearchText(searchText);
  };

  const handleCancel = () => {
    setRowSelected([]);
    setSearchText('');
  };

  useEffect(() => {
    npsHelper('1').then((res) => {
      if (res.data === 1) {
        hj('event', 'dashboard_customer_satisfaction');
      }
    });
  }, []);

  const [paginationInfo, setChangePagination] = useState({
    pageSize: defaultPage.obj_per_page, // 每一页的obj的个数
    pageNo: defaultPage.after, // 从哪一页后开始返回数据
  });

  const [queryParams, setQueryParams] = useState(defaultQuery);
  // 获取列表数据
  const {
    jobBoardInfo = {},
    data = {},
    page,
    isLoading,
    refetch: refetchDashboardData,
  } = useDashboardData(dashboardId, queryParams);

  useEffect(() => {
    const params = {
      pagination: {
        obj_per_page: paginationInfo.pageSize, // 每一页的obj的个数
        after: paginationInfo.pageNo, // 从哪一页后开始返回数据
      },
    };
    params.keyword = searchText;
    setQueryParams({
      ...queryParams,
      ...params,
    });
  }, [paginationInfo]);

  const reloadDataByDefaultPageNo = () => {
    // fix 如果页码参数相同，则直接refetchDashboardData
    if (paginationInfo.pageNo === defaultPage.after) {
      refetchDashboardData();
    } else {
      setChangePagination({
        pageSize: paginationInfo.pageSize,
        pageNo: defaultPage.after, // 从哪一页后开始返回数据
      });
    }
  };

  useEffect(() => {
    setChangePagination({
      pageSize: paginationInfo.pageSize,
      pageNo: defaultPage.after, // 从哪一页后开始返回数据
    });
  }, [dashboardId, searchText]);

  const changeQuery = (params) => {
    // 字段映射 后期可以改成enum
    if (params.filters && params.filters.status) {
      const { status = [] } = params.filters;
      params.filters.status = status.map((item) => STATUSTYPE_MAP[item]);
    }
    setQueryParams({
      ...params,
      getlist: true,
      keyword: searchText,
      pagination: {
        obj_per_page: paginationInfo.pageSize,
        after: defaultPage.after, // 从哪一页后开始返回数据
      },
    });
  };
  return (
    <>
      <div
        className={clsx('tlm-dashboard', {
          'tlm-dashboard-small-layout': changeLayOutFlag,
        })}
      >
        <AiCvNoticeInfo
          style={{ marginBottom: '25px' }}
          goAiCv={directToProfileAiBuilder}
        ></AiCvNoticeInfo>

        <JobBoardHeader
          setMainPageData={setMainPageData}
          jobBoardInfo={jobBoardInfo}
          searchText={searchText}
          handleCancel={handleCancel}
          setRows={setRows}
          STATUSTYPE={STATUSTYPE}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          dashboardId={dashboardId}
          setRefreshPageFlag={setRefreshPageFlag}
          refetch={refetchDashboardData}
          reloadDataByDefaultPageNo={reloadDataByDefaultPageNo}
          handleSearch={handleSearch}
          changeLayOutFlag={changeLayOutFlag}
          dashboardList={dashboardList.filter((item) => item.id !== dashboardId)}
          goDashboard={goDashboard}
        />
        {/* todo 这里改造 */}
        <DashBoardMainContent
          data={data}
          setRows={setRows}
          STATUSTYPE={STATUSTYPE}
          rowSelected={rowSelected}
          setRowSelected={setRowSelected}
          dashboardId={dashboardId}
          refreshPageFlag={refreshPageFlag}
          setRefreshPageFlag={setRefreshPageFlag}
          refetch={refetchDashboardData}
          paginationInfo={page}
          setChangePagination={setChangePagination}
          searchText={searchText}
          changeLayOutFlag={changeLayOutFlag}
          setChangeLayOutFlag={setChangeLayOutFlag}
          loading={isLoading}
          changeQuery={changeQuery}
          defaultProfileId={defaultProfileId}
          directToProfile={directToProfile}
        />
      </div>
    </>
  );
}

Dashboard.protoTypes = {
  dashboardId: PropTypes.string,
  refreshMain: PropTypes.func,
};

export default Dashboard;
